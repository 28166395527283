<template>
  <status-indicator
    :type="getType"
  >
    {{ getText }}
  </status-indicator>
</template>

<script>
import StatusIndicator from "@/components/elements/status/StatusIndicator";

export default {
  name: "SupplierOrderInvoiceStatus",
  components: {StatusIndicator},
  props: {
    hasReceivedInvoice: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    getType() {
      return this.hasReceivedInvoice
        ? 'primary'
        : 'danger';
    },
    getText() {
      return this.hasReceivedInvoice
        ? "Facture reçu"
        : "Facture pas reçu";
    }
  },
}
</script>

<style scoped>

</style>